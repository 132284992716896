import React from 'react';

import SEO from '../components/SEO';
import TextPage from '../components/TextPage';

const FaqPage = props => (
  <TextPage location={props.location}>
    <SEO title="私隱政策" />
    <section>
      <h1>條款及細則 (自助存倉服務)</h1>

      <p>自2018年6月1日其正式生效</p>
      <p>
        本協議由以下當事人簽訂：Roomyr Asset Management Limited (“本公司”、“我們”
        、“我方”）；和閣下，即有意使用本公司服務的客戶（“客戶”、“閣下”、“您”）。
      </p>
      <p>本協議為雙方達成以下列之協議：</p>

      <h4>1. 定義</h4>
      <p>1.1 “本公司”是指Roomyr Asset Management Limited。</p>
      <p>1.2 “服務”是指本公司為客戶提供的自助式存倉服務。</p>
      <p>1.3 “提存時段”是指客戶在該時段內可於其自助倉進行存取。</p>
      <p>1.4 “提存區域是指客戶在提存時段內可進出的存取區，本公司的儲存設施是嚴禁出入。</p>
      <p>1.5 “開始日”是指服務生效的首日。</p>
      <p>1.6 “截數日”是指每月服務的最後一天。</p>
      <p>1.7 “到期日”是指本協議期間，每月截數日的翌日，或如到期日剛好在星期六、星期日或公眾假期，則為前一個工作天。</p>
      <p>1.8 “按金”是指本協議指明的金額。</p>
      <p>1.9 “貨品”是指客戶送至並存放於自助倉內的物品。</p>

      <h4>2. 貨品儲存</h4>
      <p>
        2.1
        閣下保證，在本協議整個有效期內，存放於本公司的所有貨品仍然屬於您的財產，或貨品擁有人明確允許並授權閣下根據本協定使用本公司服務。
      </p>
      <p>2.2 為免生疑，閣下特此承認並同意本協議任何規定不應被解釋為對本公司有任何法律或衡平法上的所有權益。</p>
      <p>2.3 本公司不保證我方所使用的儲存設施、地點及方式是適合任何特定的貨物。</p>
      <p>
        2.4
        在執行本協議時，閣下確認已查看過和撿查過其自存倉並對有關其面積、大小、適合性和情況等各方面（尤其是對其安全和保安方面）表示滿意，並且放棄以有關上述各項或有關本協議任何其他事項的錯誤陳述，保證或聲明（口頭或書面）為依據的所有申索（如有的話）。
      </p>
      <p>2.5 儲存的貨物不得包括、違禁、非法、贓物或易腐物件。其他違禁貨物包括但不限於：</p>
      <p>2.5.1 化學品、毒品、任何類型的危險或有毒材料；</p>
      <p>2.5.2 食物或任何類型的易碎物件；</p>
      <p>2.5.3 易燃物、槍械、武器或任何類型的易爆物件；</p>
      <p>2.5.4 釋放任何臭味或煙氣的物件；</p>
      <p>2.5.5 植物、生物（無論是活物還是死物）；</p>
      <p>2.5.6 液體或壓縮氣；</p>
      <p>2.5.7 火柴、木炭、磷及容易燃點的物件；</p>
      <p>2.5.8 油漆、天拿水（稀釋液）及液體黏合劑；</p>
      <p>2.5.9 非法藥物、走私物品或仿制品、贓物或任何類型的非法物件；</p>
      <p>2.5.10 任何貨幣、債券或證券；</p>
      <p>2.5.11 珠寶、古董、精細工藝品、精裝酒、貴重金屬或任何高價值物件；</p>
      <p>2.5.12 個人財物會導致違反任何法律或任何政府機構的規章制度；</p>
      <p>2.5.13 任何物件本公司認為會危害人的安全和健康以及環境；</p>
      <p>2.5.14 任何裝置電池的電器。</p>
      <p>
        2.6
        此外，本公司不建議閣下將任何正本個人文件（包括但不限於護照、駕駛執照、身份證等）或任何含有個人資料（包括但不限於出生日期或銀行戶口號碼）的文件作儲存。請緊記不要儲存任何可被他人竊取身份或有急切需要的文件。在執行本協議時，閣下明白並同意如您儲存任何含有個人資料的文件或物件，閣下將放棄所有因儲存以上文件或物件所引起追究本公司的權利。
      </p>
      <p>2.7 閣下必須遵守本公司任何僱員或代理人在提存區域的指示和由本公司不時發出或修訂關於使用自助倉所述的規定。</p>
      <p>
        2.8 本公司採用商業上合理的方法維持儲存倉庫內的溫度和濕度在攝氏約15 – 28度和濕度約百分之40 –
        60。本公司採用商業上合理的方法防止霉菌在閣下的物件上生長。不過我方不能保證閣下的財產不會滋生霉菌。在執行本協議時，閣下明白並同意本公司不會承擔一切因霉菌在您的物件上自然生長而衍生的責任。
      </p>
      <p>
        2.9
        在以下情況下，本公司或代表我方的任何主體人士、機構、團體可隨時把鎖剪丟及打開閣下的自助倉，對您的貨物進行檢驗，而無需向您發出通知：
      </p>
      <p>2.9.1 我方合理認為相關儲倉內貨品可能含有第2.5條中規定的任何貨物；</p>
      <p>2.9.2 警方、消防服務、政府當局或法院判令要求本公司進行檢查；</p>
      <p>2.9.3 我方認為存在人身傷害或財產損害風險；或</p>
      <p>2.9.4 其他得到本公司管理層斟酌處理的情況。</p>
      <p>2.10 本公司在第2.9條所述的任何過程中產生的任何相關成本或損失應由閣下承擔。</p>
      <p>
        2.11
        閣下應負責確保於其不在的所有時間將自助倉鎖上，本公司將不會負責對沒上鎖之自助倉上鎖。若閣下的自助倉沒鎖上而引起您的貨品損毀或遺失，您將放棄追究本公司的權利。
      </p>
      <p>
        2.12
        若本公司合理認為儲存或繼續儲存相關貨品會對任何人士、儲存設施或儲存設施中的任何其他貨物構成安全風險，則我方可拒絕儲存任何貨品或將任何貨品退還給閣下，費用由閣下承擔。
      </p>
      <p>2.13 出於安全考慮，嚴禁進入本公司儲存設施或進入本公司分包商或代理商的儲存設施。</p>

      <h4>3. 提存時段</h4>
      <p>3.1 閣下自行安排其到自助倉進行存取。</p>
      <p>3.2 我們的提存時段如下：</p>
      <p>星期一至星期日：</p>
      <p>時段：24小時</p>
      <p>公眾假期：24小時</p>
      <p>3.3 在符合本合約之條款下，本公司保留權利不時對關於提存時段及提存區域的一般管理和保安作出的規定進行修訂。</p>

      <h4>4. 限制</h4>
      <p>4.1 閣下不得以任何違法或欺詐性方式使用服務，或將服務用於任何違法或欺詐性目的，或產生違法或欺詐性效果；</p>
      <p>4.2 閣下不得未經本公司事先書面同意，將任何服務再許可或轉售至第三方，或以任何方式、因任何原因代表本公司；</p>
      <p>4.3 閣下不得在場所內或對自助倉的使用進行任何可能使本公司的保單無效或增加其保費的事情；</p>
      <p>
        4.4
        閣下不得對場所的任何通道、樓梯、提存區域或任何其他部份造成任何妨礙或不適當的阻礙，閣下在任何時候於使用該地區時給予別人體恤。
      </p>
      <p>
        4.5
        閣下不得進行任何事情，或容許或允許任何人行使或將會使本協議給予的權利以進行任何事情，而此等事情就場所而言會導致或可能導致本公司違反我方根據任何租約應負的任何契約承諾或其他義務。
      </p>

      <h4>5. 其他場地</h4>
      <p>5.1 本公司可隨時向閣下發出7日書面通知把您的自助倉搬遷到我方指定的其他場地。在這情況下，運輸費用由我方承擔。</p>
      <p>5.2 若閣下的自助倉被搬遷到我方指定的其他場地，本協議將繼續有十足效力，其服務月費亦將繼續適用至本協議完結。</p>

      <h4>6. 服務月費</h4>
      <p>
        6.1
        閣下應在簽署本協議時，就本公司提供的最短儲存期支付相關服務月費，並應之後按協議上的到期日預先支付餘下的服務月費，不作任何扣減或抵銷。
      </p>
      <p>6.2 本公司接受現金、支票、銀行轉賬付款 。</p>
      <p>6.3 若本公司未出現過錯，而閣下停止向本公司付款，本公司則可一次性收取HK$500罰款 。</p>
      <p>6.4 本公司可向閣下發出書面通知後隨時增加服務月費，該增加於通知日期後至少兩個星期的第一個到期日生效。</p>
      <p>6.5 若閣下於合約完結前取消服務，仍需要付整段合約之費用，而已預繳之費用在任何情完下將不獲退還。</p>

      <h4>7. 按金</h4>
      <p>
        7.1
        閣下應在簽署本協議時向本公司支付按金，按金將於本協議終止後三十日內無利息歸還閣下，但我方有權從按金中扣除與下述項目有關的任何款項：
      </p>
      <p>A. 就閣下、其代理人或被邀請人或由存於其自助倉內的貨品，對自助倉及場所造成的任何損毀進行修理；</p>
      <p>B. 任何未付的服務月費或遷移費用或其他費用；</p>
      <p>C. 閣下對本公司應負有而尚未全部履行的任何其他責任。</p>
      <p>
        7.2
        若服務月費按照本協議第6.4條有任何增加，閣下應在上述每個情況另以保證金方式向我方支付一筆款項，此筆款項相等於我方當時持有的原按金額與服務月費增加後應持有的按金額的差額，此筆款項應在是項增加後於下一個到期日(如適用)一同支付。
      </p>

      <h4>8. 欠繳服務月費</h4>
      <p>
        8.1
        若閣下未能及時向本公司支付所有到期應付的費用或款項，將免除本公司因貨品產生的任何職責。本公司將行使保留貨品的權利，直到全額清償且我方全額收到所有結欠費用或款項。在此情況下，閣下授權本公司扣留貨品且授權本公司檢查貨品。
      </p>
      <p>
        8.2
        若閣下未能在到期日前及在15天寬限期後安排向本公司支付任何應付金額，本公司將收取HK$500遲交費用罰款，從到期日起計直到到期日後90天，每15天收取一次。
      </p>
      <p>
        8.3
        若閣下在到期日後60日內未能安排向我方支付任何應付的相關金額，本公司有權把鎖剪丟並保留扣留閣下在本公司儲存的貨品並出售或拍賣的權利。若適用，本公司還將收取留置變賣/拍賣費HK$1000。變賣或拍賣收益將用於清還所有未付費用及款項，包括行政管理費、遲交費用罰款及留置變賣/拍賣費。若在清還所有費用和款項後，有任何餘款，本公司將盡合理努力將餘款無息退還至閣下。若在60日內，本公司未能收到閣下回復，無法向閣下支付餘款，則餘款將由本公司保留。
      </p>
      <p>
        8.4
        若根據前述規定變賣貨品無法收回閣下到期應付給本公司的所有費用及款項，閣下有義務在七日內清償到期應付給本公司的任何未付餘額。若未在七日內清還，本公司可要求收債機構收回所有到期款項，且因該過程產生的所有費用應由閣下承擔。
      </p>
      <p>
        8.5
        在考慮了銷售成本之後，本公司可使用任何可用的合理方式變賣貨品，達到合理市場售價。若因任何原因貨品無法合理或經濟出售，本公司有許可權將貨品視作遺棄處理並可以予以處置或銷毀。所有變賣或處置成本應由閣下承擔。
      </p>

      <h4>9. 不轉讓</h4>
      <p>本協議的利益純粹是閣下個人的並不可以轉讓。</p>

      <h4>10. 責任限制</h4>
      <p>10.1 在任何情況下，我方均不會就本公司第10條所述保單範圍之外的任何貨品損壞或損失負責或承擔責任。</p>
      <p>10.2 閣下確認本公司不知悉在我方儲存的貨品價值。閣下有責任為您的貨物合理投保。</p>
      <p>
        10.3
        閣下確認並同意使用本公司網站及本公司任何服務時完全由閣下承擔風險。本公司不以任何方式就使用本公司網站或誤解網站內容而產生的任何直接或間接損害負責或承擔責任。
      </p>
      <p>
        10.4
        閣下應負責確保對貨品進行仔細妥善包裝及採用儲存時合理所需的充分保護措施。對於因包裝或保護措施不充分或不妥當、貨品隨著時間的推移可能出現的變質、閣下決定存儲的物件的易碎性、任何貨物缺陷或出於法律原因進行的任何貨物沒收或扣押而產生的任何物件損失，本公司一概不負責。
      </p>
      <p>
        10.7
        因本協議項下的失實陳述或任何默示保證或條件或明示規定等所有情況，本公司及其僱員或代理人均不就根據本協議產生的或本協議有關的任何損失或損害費用、開支或其他申索（不論是否因本公司及其僱員或代理人或其他人的疏忽所造成）對閣下負責。
      </p>

      <h4>12. 終止</h4>
      <p>
        12.1
        任何一方可在到期日前向另一方發出至少十五日書面通知隨時終止本協議（有含約期除外），任何上述終止於該通知上指明的日期生效，而無損於任何一方因另一方先前違反本協議任何規定而可能有的權利。
      </p>
      <p>12.2 若閣下尚未支付根據本協議應付的任何服務月費或其他費用或閣下違反本協議，閣下不得終止協議。</p>
      <p>12.3 若閣下違反本協議，本公司可向閣下發出書面通知終止本協議，儘管可能有任何通知期，但該終止應立即生效。</p>
      <p>12.4 本協議終止後，閣下應將所述貨品移離自助倉並使倉內處於整潔齊整如同與開始日相同的狀態。</p>
      <p>
        12.5 本協議終止後，閣下應就我方將自助倉恢復原狀或處置留在倉內任何所述貨品和廢棄物所招致的任何費用對本公司負責。
      </p>
      <p>
        12.6
        若閣下沒有在截數日後7日內或在本協議終止後7日內將全部所述貨品移離自助倉，本公司保留權利按上述第8條所指明的方式將所述貨品視為被棄置，並在之後出售、銷毀或處置所述貨品和運用任何出售的收入。若經處置所述貨品的收入不足以支付根據本協議到期的金額，我方保留其他權利之後再向閣下收取其他費用。
      </p>

      <h4>13. 規章制度</h4>
      <p>
        本公司應自由地不時訂定有關使用自助倉的規章制度。閣下應遵守和履行該等規章制度，就如該等規章是本協議的條款一樣。
      </p>

      <h4>14. 不可抗力及惡劣天氣</h4>
      <p>14.1 若因不可抗力導致本公司未能提供或延遲提供服務，本公司將不對此負責或承擔責任。</p>
      <p>
        14.2
        不可抗力指本公司合理控制範圍之外的任何不可預見的情況，包括但不限於戰爭、戰爭威脅、恐怖活動、罷工或其他勞工行動、暴動、火災、暴風或任何其他自然災害、電信網路故障、停電、交通環境、道路封閉、意外或任何其他不可預見的情況。
      </p>
      <p>14.3 若發生不可抗力，本公司將努力儘快與閣下取得聯繫，並將採取所有合理措施減少對服務的影響。</p>

      <h4>15. 賠償</h4>
      <p>
        15.1
        對於因閣下的任何違約行為產生的所有索賠、責任、損害、成本、費用、損失及法律費用，閣下同意根據要求向本公司、本公司職員、代理、供應商及董事進行賠償、為其進行抗辯並使其不致因此而遭受損害。本條款同樣適用於因閣下使用本網站或服務，或您的帳戶下任何其他主體使用本網站或服務而產生的任何其他責任。
      </p>
      <p>
        15.2
        若在本協議終止時閣下未將全部所述貨品移離自助倉，和未按照本協議第12.4條將處於整潔和齊整狀態和處於與開始日相同狀態的自助倉交還，閣下應就本公司因此受到的任何損失或損害和招致的所有費用和開支，對本公司作出彌償，並使我方獲得上述各項的彌償。
      </p>

      <h4>16. 通知</h4>
      <p>16.1 閣下發送給本公司的任何通知必須以書面形式通過電子郵件發送至info@roomyr.com。</p>
      <p>
        16.2
        通知應在寄件者發出電子郵件後視為已發送，但前提是電子郵件寄件者未收到電子郵件指明擬定收件人沒有收到電子郵件信息。
      </p>

      <h4>17. 免責條款</h4>
      <p>
        17.1
        本公司以當前可用的狀態提供網站，包括其中的所有錯誤。本公司不保證使用網站時將不含任何錯誤或不中斷或任何缺陷將得以糾正。
      </p>
      <p>17.2 本公司將基本根據本協定規定，採用合理謹慎態度和技能提供服務。本公司不就服務作出任何其他承諾或擔保。</p>

      <h4>18. 適用法律及語言</h4>
      <p>18.1 本協定及因本協定或本協定標的或形式構成而產生的或與其相關的任何爭議應受香港法律管轄並據其解釋。</p>
      <p>18.2 若中文版本與英文版本之間存在任何不一致之處，應以英文版本為準。</p>

      <h4>19. 隱私</h4>
      <p>
        本公司僅根據本公司的私隱政策使用閣下的個人資訊，本公司私隱政策可隨時在本網站上查閱。煩請抽時間閱讀私隱政策，因為其中包括了適用於閣下及您的資訊處理方式的重要條款。
      </p>

      <h4>20. 其他約定</h4>
      <p>20.1 本協議不應對閣下設定任何租約，亦不賦予閣下任何租貸權利，本公司與閣下之間亦不應構成業主和租戶的關係。</p>
      <p>20.2 若客戶有兩個或以上的人士，其在本協議項下的義務應是共同和個別的。</p>
      <p>20.3 本公司保留權利拒絕接受任何貨品而不提供任可理由。</p>
      <p>
        20.4
        本協定及本協定任何書面修正案構成雙方就標的所達成的完整協定，並取代和排除雙方先前達成的任何口頭或書面的協定、諒解或安排。除本協定中指出的本公司隱私政策外，不存在雙方已達成一致但未在本協議中全面規定的聲明、擔保或協定，且除本協定的明確規定外，未授權本公司代表或其代理作出任何聲明、擔保或約定。除非本協定中另有規定，否則所有修正必須以書面形式作出並經雙方簽署。
      </p>
      <p>
        20.5
        本公司保留不時對本協議條款和條件進行修正的權利，且在每次購買本公司服務時，閣下應有責任查看本協議條款和條件。本協議的最新版本始終可在
        www.roomyr.com上獲取。閣下持續使用本公司服務將視為代表閣下持續接受本協議最新版本。
      </p>
      <p>20.6 如有任何爭議，本公司將保留最終決定權。</p>
      <p>20.7 閣下或本公司未能行使或強制執行本協議的任何權利或條款不得構成放棄相關權利或條款。</p>
      <p>
        20.8 本協議所有條款應平等適用于Roomyr Asset Management Limited
        Limited公司、其子公司、本公司的任何控股公司、本公司（或前述各個主體）的關聯方及本公司（和前述各個主體）的第三方資訊提供商,
        加盟商及許可方，並應符合其利益，且前述每個主體均應有權直接或代表其自身主張並強制執行相關條款（但是，本協議可在未獲得前述各方同意的情況下進行變更或撤銷）。在遵守前一句規定的前提下，本協議任何條款不可由本協議當事人之外的任何主體強制執行。
      </p>
      <p>
        20.9
        若具有管轄權的任何法院、仲裁機構或行政管理機構確認本協議任何條款無效或不可執行，則相關無效性或不可執行性不得影響本協議其他條款，其他條款應繼續全面有效並具有效力。若本協議任何條款如前所述被認定為無效或不可執行，但在刪除部分內容後可具有效力或可以執行，則應對相關條款進行合理必要的修正，以便使其有效且同時反映雙方意圖。
      </p>
      <p>
        20.10
        在任何情況下，本協定中從其本身性質而言可在終止後合理存續的所有條款應在終止後存續有效，包括但不限於所有權條款、擔保免責條款及責任限制條款。若閣下再次使用網站或服務，則閣下對網站或服務的再次使用將受屆時適用的條款和條件管轄。若閣下使用在本協定項下購買的服務，則適用於相關服務的相關條款將在本協議終止後存續有效。
      </p>
      <p>20.11 各方均應對本協議雙方之間披露的任何及所有資訊進行嚴格保密。本條規定應在本協議終止後仍存續有效。</p>
      <p>20.12 本協議應受香港法律管轄，本協議雙方將依香港特別行政區法院的專屬管轄權。</p>
    </section>

    <section>
      <header>條款及細則 (自助存倉服務)</header>
      條款及細則 (流動存倉服務)
      <p>自2018年8月 1日其正式生效</p>
      <p>
        您好，歡迎檢閱我們的條款及細則。此文件十分重要，故請仔細閱讀。在使用本公司網站或手機應用程式及產品和服務時適用本條款和細則。在訪問本公司網站、使用服務、購買產品以及使用手機應用程式期間，您確認已閱讀並理解條款及細則
        (本“協議”)、接受這些協議且同意受其約束。若您不同意 (或不能遵守)
        本協議，則可能無法使用我們的服務或享用任何內容。此外，您亦承諾提交至本公司的所有註冊資訊為真實、準確而完整，且同意始終保持該狀態。您同意本公司可根據本協議的私隱政策使用閣下資訊。另外，您保證有合法權利，可代表閣下自身或代表服務受益人使用本公司服務。
      </p>
      <h4>1. 協議當事人</h4>
      <p>
        本協議由以下當事人簽訂：Roomyr Asset Management Limited公司 (“本公司”、“我們”
        、“我方”）；和閣下，即有意使用本公司服務的客戶（“客戶”、“閣下”、“您”）。
      </p>
      <h4>2. 定義</h4>
      <p>2.1 “本公司”是指Roomyr Asset Management Limited 。</p>
      <p>2.2 “服務”是指本公司在任何時候可能提供的所有或任何服務及產品。</p>
      <p>
        2.3
        “派送”是指任何空箱/流動車交送、已包裝貨箱/倉及貨品提取、提取跟進、已包裝貨箱/倉及貨品配送、空箱/流動車回收及其他遞送服務。
      </p>
      <p>2.4 “貨箱”是指本公司提供的儲物箱。</p>
      <p>2.5 “貨物”或“貨品”是指委託本公司存儲的物品，包括裝入貨箱的物品及未裝入貨箱的物品。</p>
      <p>
        2.6 “網站”是指本公司網站www.roomyr.com或本公司提供服務並進行服務宣傳的任何其他網站，或後續的替代性URL（網址）。
      </p>
      <p>2.7 “服務生效日期”是指由客戶使用箱／車或貨品並送到倉庫當日起的7天之後，以較早日期為準。</p>
      <p>2.8 “相關金額”是指月費及所有適用費用。</p>
      <h4>3. 費用及手續費用</h4>
      <p>A. 標準月費：</p>
      <p>– 跟據雙方簽定合約為準</p>
      <p>B. 運送及手續費：</p>
      <p>– 空箱/車派送費: 每程報價</p>
      <p>a. 儲物箱</p>
      <p>I. 15分鐘內完成地面交收</p>
      <p>II. 地面交收超過15分鐘 首15分鐘免費，其後$50每半小時 +</p>
      <p>相關停車場或泊車費用</p>
      <p>– 送回費用 (單程) 每程報價</p>
      <p>– 代棄置儲物箱/流動車內物品處理費用</p>
      <p>I. 儲物箱 HK$200/每箱</p>
      <p>C. 其它費用及罰款：</p>
      <p>– 預約取消費 (客戶在預約的一天前下午5時後更改或取消預約) HK$200</p>
      <p>– 預約後客戶沒有出現 HK$200</p>
      <p>– 派送失敗費用 (不是由我方引致) HK$200</p>
      <p>– 遺失或損壞儲物箱及流動車費</p>
      <p>a. 標準儲物箱 HK$200/每箱</p>
      <p>– 超重費用 (單程)</p>
      <p>a. 貨箱或物品 HK$200/每箱或每件，</p>
      <p>本公司有權拒收入倉而不作退款。</p>
      <p>– 遲交帳單費用 (寬限期過後每天收取一次) HK$500</p>
      <p>– 留置變賣/拍賣費 HK$1000</p>
      <p>– 如派送空箱後不使用儲存服務及超過派送空箱後7天(即冷靜期)，運費會按次收取 HK$200</p>
      <p>– 拒付費 (不是我們的問題而拒絕付款) HK$200</p>
      <p>- 本公司保留及擁有任何時候更改所有服務與產品的價目之一切權利</p>
      <h4>4. 註冊和帳戶</h4>
      <p>
        4.1
        本公司將要求閣下提供貴方的個人資訊，包括姓名、電郵地址、手機號碼及住址。根據本公司的隱私政策規定，閣下需負責確保您提供的個人資訊真實、準確而完整及且為最新資訊。
      </p>
      <p>
        4.2
        註冊時，閣下將選定用戶名、電郵地址和密碼（登錄資訊）。閣下應負責對您的登錄資訊進行保密，並採取所有合理措施確保除閣下外，其他任何人均無法使用您的帳戶及服務。閣下同意持有您登錄資訊的任何人士均有許可權作為您的代表，使用本公司的服務及網站。閣下需對您的帳戶下產生的所有活動負責。
      </p>
      <p>4.3 若閣下發現或懷疑其他人獲悉了您的登錄資訊，則閣下應及時通知本公司。</p>
      <p>
        4.4
        閣下如需更改住址、電話及電郵地址，可致電我們的客戶服務熱線或通過電子郵件發送至info@roomyr.com，聯絡我們的客戶服務主任，我們會盡快回覆。如閣下更新的住址是我們的服務尚未覆蓋或沒電梯可到達，我們會把您已存倉的貨箱及物件免費送回到閣下之前登記的住址。
      </p>
      <p>
        4.5
        未經本公司事先書面同意，閣下不得將任何服務再許可或轉售至任何廠商及人士，亦不得以任何方式、因任何原因代表本公司。
      </p>
      <h4>5. 貨品儲存</h4>
      <p>
        5.1
        閣下保證，在本協議整個有效期內，委託給本公司的所有貨物仍然屬於您的財產，或貨物擁有人明確允許並授權閣下根據本協定使用本公司服務。
      </p>
      <p>5.2 所有我方提供的儲物膠箱及流動車仍舊是本公司的財產。</p>
      <p>5.3 本公司不保證我方所使用的儲存設施、地點及方式是適合任何特定的貨物。</p>
      <p>
        5.4
        每個標準儲物箱不得超過25公斤。若超重，我們會向閣下收取每箱HK$200的超重費用。另流動車不得超過300公斤，若超重而導致流動車不能順暢地推動，本公司有權拒收入倉而不作退款。閣下負責仔細對所有貨物進行包裝，並使用保護性包裝紙或墊料對貨物進行保護。儲存於本公司儲物箱中的貨物必須使用本公司在收取儲物箱時向閣下提供的安全封條進行完全密封。致於流動車，客戶需要自行把流動車上鎖。
      </p>
      <p>5.6 儲存的貨物不得包括、違禁、非法、贓物或易腐物件。其他違禁貨物包括但不限於：</p>
      <p>5.6.1 化學品、毒品、任何類型的危險或有毒材料；</p>
      <p>5.6.2 食物或任何類型的易碎物件；</p>
      <p>5.6.3 易燃物、槍械、武器或任何類型的易爆物件；</p>
      <p>5.6.4 釋放任何臭味或煙氣的物件；</p>
      <p>5.6.5 植物、生物（無論是活物還是死物）；</p>
      <p>5.6.6 液體或壓縮氣；</p>
      <p>5.6.7 非法藥物、走私物品或仿制品、贓物或任何類型的非法物件；</p>
      <p>5.6.8 任何貨幣、債券或證券；</p>
      <p>5.6.9 珠寶、古董、精細工藝品、精裝酒、貴重金屬或任何高價值物件；</p>
      <p>5.6.10 個人財物會導致違反任何法律或任何政府機構的規章制度；</p>
      <p>5.6.11 任何物件本公司認為會危害人的安全和健康以及環境；</p>
      <p>5.6.12 任何裝置電池的電器。</p>
      <p>
        5.7
        此外，本公司不建議閣下將任何正本個人文件（包括但不限於護照、駕駛執照、身份證等）或任何含有個人資料（包括但不限於出生日期或銀行戶口號碼）的文件存入儲物箱或流動車內。請緊記不要儲存任何可被他人竊取身份或有急切需要的文件。在執行本協議時，閣下明白並同意如您儲存任何含有個人資料的文件或物件，閣下將放棄所有因儲存以上文件或物件所引起追究本公司的權利。
      </p>
      <p>
        5.8
        即使閣下的儲物箱或物件已經包裝妥當，儲物箱或流動車內的物件會在運送途中移動或受擠壓。因此請不要儲存任何易碎物件（包括但不限於玻璃器皿、陶瓷、相架、燈泡、樂器、收藏品等）。在執行本協議時，閣下明白並同意一旦您儲存該等易碎物件，閣下將放棄所有以上物件損壞所引起追究本公司的權利。
      </p>
      <p>
        5.9 本公司採用商業上合理的方法維持儲存倉庫內的溫度和濕度在攝氏約15 – 28度和濕度約百分之40 –
        60。本公司採用商業上合理的方法防止霉菌在閣下的物件上生長。不過我方不能保證閣下的財產不會滋生霉菌。在執行本協議時，閣下明白並同意本公司不會承擔一切因霉菌在您的物件上自然生長而衍生的責任。
      </p>
      <p>
        5.10
        在以下情況下，本公司或代表我方的任何主體人士、機構、團體可隨時打開閣下的儲存箱或流動車內的貨品，對您的貨物進行檢驗，而無需向您發出通知：
      </p>
      <p>5.10.1 我方合理認為相關儲存箱或流動車內的貨品可能含有第5.6條中規定的任何貨物；</p>
      <p>5.10.2 警方、消防服務、政府當局或法院判令要求本公司進行檢查；</p>
      <p>5.10.3 我方認為存在人身傷害或財產損害風險；或</p>
      <p>5.10.4 其他得到本公司管理層斟酌處理的情況。</p>
      <p>5.11 本公司在第5.10條所述的任何過程中產生的任何相關成本或損失應由閣下承擔。</p>
      <p>
        5.12
        若本公司合理認為儲存或繼續儲存相關貨物會對任何人士、儲存設施或儲存設施中的任何其他貨物構成安全風險，則我方可拒絕儲存任何貨物或將任何貨物退還給閣下，費用由閣下承擔。
      </p>
      <p>5.13 出於安全考慮，嚴禁進入本公司儲存設施或進入本公司分包商或代理商的儲存設施。</p>
      <h4>6. 派送及收取</h4>
      <p>
        6.1
        我們的服務範圍覆蓋香港島、九龍及新界、及指定離島地區：馬灣、東涌，其他地區或所屬地區需要申請禁區許可證、車輛未能到達，本公司此刻恕未能提供服務。
      </p>
      <p>
        6.2
        閣下需要確保我方或其承運人有合理許可權進入閣下場地並提供有停車設施，以便我方向閣下進行派送或取貨。閣下需確保我方可在大廈大堂通過電梯可容易到達您的範圍內派送或取貨。如閣下的派送範圍是沒電梯可到達，本公司此刻是不會提供服務。
      </p>
      <p>
        6.3
        閣下應確保已將貨物安全裝入儲存箱、流動車或已妥善作好其他運輸準備工作，以便貨物不會通過潮氣擴散、蔓延、洩露、煙氣或物質溢出或通過其他形式對本公司的財產、雇員、代理、承包商或其他貨物造成損害或傷害，或可能造成損害或傷害。
      </p>
      <p>6.4 若在派送過程中貨物或閣下財產出現任何損害，本公司保留拒絕閣下索賠的權利。</p>
      <p>
        6.5
        需要派送服務時，閣下應進行預約，且您需負責確保閣下或閣下的授權人在指定的時段內，前往閣下提供的接收地址收取儲存箱、或流動車。若需要更改任何預約，您應在派送一天前下午5點前通知我方。
      </p>
      <p>6.5.1 從閣下指定時間後修改或取消預約，將收取HK$200的費用。</p>
      <p>6.5.2 若閣下或閣下的有關人員未在派送預定時間到場，則將視為派送失敗並收取HK$200的費用。</p>
      <p>
        6.6
        本公司不就任何取消或派送承擔責任，包括但不限於本公司派送資源配置不當、計劃衝突、任何不利天氣條件、惡劣天氣預警、交通條件、本公司控制範圍之內或之外的任何事件或由不可抗力（遵守第16條中列出的其他條款和規定）導致的任何事件。
      </p>
      <p>
        6.7
        本公司有權決定拒收不符合第5.8條規定的任何貨箱或貨品。若由此導致派送失敗，閣下將負責支付HK$200的派送失敗費用。
      </p>
      <p>6.8 如客戶的同一訂單不能一次過首次存倉，餘下需要存倉的貨箱流動車的首次存倉費用將會按每程相關的運費收取。</p>
      <p>6.9 流動車超時等候收取服務要視乎當天的送取運輸安排，本公司保留能否提供此服務的最終之決定權。</p>
      <p>
        6.10
        本公司提供給閣下的膠箱僅用於包裝委託給本公司儲存貨物。閣下需負責在最初預約本公司送交空箱之日後7日內，預約收取已包裝的貨箱。本公司前去收取貨箱時，閣下必須退還先前送交給您的所有貨箱。
      </p>
      <p>6.10.1 若貴方保留空的存儲貨箱超過7天，本公司將向您收取費用，視同貨箱由我方存儲在本公司的儲存設施之中。</p>
      <p>
        6.10.2 若任何貨箱或流動車丟失或損壞，本公司將向您收取每個貨箱HK$200 (不論是標準箱或特大箱)
        或每個流動車HK$4000的費用。
      </p>
      <p>
        6.10.3 若任何貨箱和流動車和貨品的取件等候時間超過20分鐘，本公司保留另行安排取件的權利並向您收取$30+
        每箱/件$30/$80 (登記地址/非登記地址) 的提取儲物箱運費或$30 + 每流動車$90/$150 (登記地址/非登記地址)
        的提倉運費另加隧道費如適用。
      </p>
      <p>6.10.4 若閣下希望保留空的貨箱，本公司將向您收取每個貨箱HK$200的費用 (不論是標準箱或特大箱)。</p>
      <p>
        6.10.5 閣下享有7天的冷靜期
        (由派送空箱開始計算)，如在冷靜期內取消及退還貨箱，本公司不會收取任何費用。冷靜期只限於標準儲物箱及特大儲物箱的服務，流動車服務不適用。
      </p>
      <p>6.11 運輸需於24小時前預約，實際安排視乎本公司運送團隊情況而定。</p>
      <p>
        6.12
        當閣下在網上預約派送或提取訂單後，我們的客戶服務主任會與您聯絡確應日期及時間，所有網上預約派送或提取的日期及時間必須由我們的客戶服務主任與閣下確應後才可作實。
      </p>
      <h4>7. 限制</h4>
      <p>7.1 閣下不得：</p>
      <p>7.1.1 以任何違法或欺詐性方式使用服務，或將服務用於任何違法或欺詐性目的，或產生違法或欺詐性效果；</p>
      <p>7.1.2 未經本公司事先書面同意，將任何服務再許可或轉售至第三方，或以任何方式、因任何原因代表本公司；</p>
      <p>7.1.3 試圖干擾或破壞服務、網站或網站中使用或連接的任何伺服器或網路，或未經授權訪問任何前述伺服器或網路。</p>
      <h4>8. 款項和費用</h4>
      <p>8.1 每月服務費是付港幣按月計算及必須預繳。</p>
      <p>
        8.2
        首次入倉的前一天便是每月賑單周期的截數日，每月賬單周期是按服務生效日期至每月的截數日計算。如服務終止日並非賬單周期截數日的最後一天，客戶必須承擔整個月的月費。如客戶使用合約計劃，客戶必須完成整個合約週期，假若客戶提前終止合約，客戶仍需繳付整個合約週期的月費，而任何情況下，已預繳的費用將不獲退還。客戶使用服務所獲取的所有禮券或禮品均不可轉換或兌換現金或其他利益。如客戶於承諾期內提早終止服務，我們或向閣下收取贈品或禮品之全部價值。
      </p>
      <p>8.3 本公司接受支票、銀行轉賬或現金付款。</p>
      <p>8.5 所有適用的費用列明於預約時網站上的規定或本協議中。</p>
      <p>8.6 本公司可酌情對所有適用費用進行變更並即時生效。</p>
      <p>8.7 若本公司未出現過錯，而閣下停止向本公司付款，本公司則可一次性收取HK$1000罰款 。</p>
      <p>
        8.8
        若閣下未能及時向本公司支付所有到期應付的費用或款項，將免除本公司因貨物產生的任何職責。本公司將行使保留貨物的權利，直到全額清償且我方全額收到所有結欠費用或款項。在此情況下，閣下授權本公司扣留貨物且授權本公司檢查貨物。
      </p>
      <p>
        8.9
        若閣下未能在到期日前及在15天寬限期後安排向本公司支付任何應付金額，本公司將收取HK$500遲交費用罰款，從到期日起計直到到期日後90天，每1天收取一次。
      </p>
      <p>
        8.10
        若閣下在到期日後60日內未能安排向我方支付任何應付的相關金額，本公司保留扣留閣下在本公司儲存的貨物並出售或拍賣的權利。若適用，本公司還將收取留置變賣/拍賣費HK$1000。變賣或拍賣收益將用於清還所有未付費用及款項，包括行政管理費、遲交費用罰款及留置變賣/拍賣費。若在清還所有費用和款項後，有任何餘款，本公司將盡合理努力將餘款無息退還至閣下。若在60日內，本公司未能收到閣下回復，無法向閣下支付餘款，則餘款將由本公司保留。
      </p>
      <p>
        8.11
        若根據前述規定變賣貨物無法收回閣下到期應付給本公司的所有費用及款項，閣下有義務在七日內清償到期應付給本公司的任何未付餘額。若未在七日內清還，本公司可要求收債機構收回所有到期款項，且因該過程產生的所有費用應由閣下承擔。
      </p>
      <p>
        8.12
        在考慮了銷售成本之後，本公司可使用任何可用的合理方式變賣貨物，達到合理市場售價。若因任何原因貨物無法合理或經濟出售，本公司有許可權將貨物視作遺棄處理並可以予以處置或銷毀。所有變賣或處置成本應由閣下承擔。
      </p>
      <p>
        8.13
        若閣下請求本公司處置閣下一個或多個貨箱或流動車中的財產，本公司將收取每個貨箱HK$200或每個流動車HK$1000的處置費，但閣下需簽訂一份全面解除協議。
      </p>
      <h4>9. 推廣優惠</h4>
      <p>9.1 推廣優惠只限於初次登記客戶使用。初次登記客戶是指新客戶首次預約派送和收取儲物箱或流動車。</p>
      <p>9.2 推廣優惠只能供每個帳戶及每個登記地址使用一次。</p>
      <p>9.3 推廣優惠不可轉售或轉讓予他人。</p>
      <p>9.4 推廣優惠不可兌換現金或退款。</p>
      <p>9.5 不包括優惠以外的費用。</p>
      <p>9.6 推廣優惠不能與其他折扣同時使用。</p>
      <p>9.7 推廣優惠只適用於推廣期內，逾期作廢。</p>
      <p>
        9.8
        如違反優惠條款或有任何濫用之情況，優惠將作廢，同時客戶使用服務所獲取的所有禮券或禮品均不可轉換或兌換現金，本公司或會向閣下收取贈品或禮品的全部價值。
      </p>
      <p>9.9 如有任何爭議，本公司保留最終之決定權。</p>
      <h4>11. 責任限制</h4>
      <p>
        11.1
        在任何情況下，我方均不會就本公司第10條所述保單範圍之外的任何貨物損壞或損失負責或承擔責任。另為防止爭議，這包括儲存箱及流動車的安全封條或鎖已毀壞。
      </p>
      <p>11.2 閣下確認本公司不知悉在我方儲存的貨物價值。閣下有責任為您的貨物合理投保。</p>
      <p>
        11.3
        閣下確認並同意使用本公司網站及本公司任何服務時完全由閣下承擔風險。本公司不以任何方式就使用本公司網站或誤解網站內容而產生的任何直接或間接損害負責或承擔責任。
      </p>
      <p>
        11.4
        閣下負責對本公司歸還給您的任何物件進行檢查，確認是否丟失或毀壞。若閣下認為物件有丟失或毀壞，則需在配送後48小時內告知我方。本公司保留在進行保險理賠前對貨箱、貨品及貨物進行檢查並對任何聲稱的毀壞或損失進行拍照的權利。
      </p>
      <p>
        11.5
        閣下應負責確保對貨箱、貨品及貨物進行仔細妥善包裝及採用儲存時合理所需的充分保護措施。對於因包裝或保護措施不充分或不妥當、貨物隨著時間的推移可能出現的變質、閣下決定存儲的物件的易碎性、任何貨物缺陷或出於法律原因進行的任何貨物沒收或扣押而產生的任何物件損失、配送錯誤及損壞，本公司一概不負責。
      </p>
      <p>
        11.6
        在任何情況下，本公司就每個儲物箱/流動車或貨物的責任總額（無論是否在保險理賠的情況下）不得超過：（i）損失或損壞的貨物成本；（ii）理賠之日貨物的當前重置成本；或（iii）含有貨物的每個貨箱和/或儲存貨物提供最高保險賠償為HK$500；
        若進行理賠，我方有權要求提供貨物丟失或損壞的證據，以及相關貨物重置成本或原始成本的憑證。
      </p>
      <p>11.7 若進行理賠，本公司應有權要求提供貨箱或流動車內物件和/或儲存貨物的成本價及當前重置成本的證據。</p>
      <p>11.8 儘管有前述規定，本協議中的任何條款均不得影響或限制閣下任何法定權利。</p>
      <h4>12. 終止</h4>
      <p>
        12.1
        閣下可隨時請求歸還儲存在儲存貨箱或流動車中的貨物或物件並清償到期應付給本公司的任何結欠費用，從而終止本協議。
      </p>
      <p>
        12.2
        本公司可因任何原因終止本協議，但至少提前15天向閣下發出書面通知。在此情況下，本公司將向閣下退還任何預付的月費並安排將您的物件送還至閣下，費用由本公司承擔。
      </p>
      <p>12.3 若出現以下情形，本公司可隨時向閣下發出書面通知，終止本協議，且即時生效：</p>
      <p>12.3.1 閣下未能在到期日支付任何到期款項，或</p>
      <p>12.3.2 閣下違反本協議任何條款。</p>
      <p>
        12.4
        終止後，閣下必須確保與本公司聯繫，安排歸還您的貨箱及流動車內的貨品及貨物，費用由閣下承擔，且必須在通知後7日內安排歸還。若因任何原因在本協議終止後7日內，閣下未能安排歸還您的物件，則我方可根據第8條規定處置相關物件。
      </p>
      <p>
        12.5
        當送回閣下的貨箱及流動車後，我們的運送團隊會等候20分鐘給閣下清理及歸還空箱及流動車，如超過20分鐘的等候時間：
      </p>
      <p>A. 儲存箱：本公司保留另行安排取回空箱的權利並向您收取相關的運費；</p>
      <p>
        12.6
        閣下必須在送回您的貨箱、貨品及貨物後7天內歸還所有空箱，如閣下未能在7天內歸還所有空箱，本公司會向閣下收取每箱$200
        (不論標準儲物箱或特大儲物箱)的費用。
      </p>
      <h4>13. 知識產權</h4>
      <p>
        13.1
        本公司保留對本網站及服務相關的各類知識產權的所有權，包括所有適用的版權、專利、商標及其他產權，除了本公司在本網站中或在提供服務期間提及第三方相關的知識產權。
      </p>
      <p>13.2 允許以有限、非獨有、不可許可及不可轉讓的形式使用有限內容，且僅供私用，不得用於商業用途。</p>
      <h4>14. 隱私</h4>
      <p>
        14.1
        本公司僅根據本公司的私隱政策使用閣下的個人資訊，本公司私隱政策可隨時在本網站上查閱。煩請抽時間閱讀私隱政策，因為其中包括了適用於閣下及您的資訊處理方式的重要條款。
      </p>
      <p>
        14.2
        為有效快速提供服務，本公司可使用定位服務。本公司通過使用前述定位服務而從閣下採集的資訊應根據本公司私隱政策的條款進行收集和儲存。閣下同意在接受本協議條款時，同意本公司使用定位服務。
      </p>
      <h4>15. 分包及轉讓</h4>
      <p>15.1 本公司可隨時將其在本協議項下的任何或所有權利和義務分包、分派或轉讓至第三方或代理，且無需閣下同意。</p>
      <p>15.2 明確禁止對閣下在本協議項下的任何義務進行分包、分派與、轉讓或再許可，無論是否為了獲取商業利益。</p>
      <p>15.3 本協議應對閣下的私人代表及繼承人具有約束力。</p>
      <h4>16. 不可抗力及惡劣天氣</h4>
      <p>16.1 若因不可抗力導致本公司未能提供或延遲提供服務，本公司將不對此負責或承擔責任。</p>
      <p>
        16.2
        不可抗力指本公司合理控制範圍之外的任何不可預見的情況，包括但不限於戰爭、戰爭威脅、恐怖活動、罷工或其他勞工行動、暴動、火災、暴風或任何其他自然災害、電信網路故障、停電、交通環境、道路封閉、意外或任何其他不可預見的情況。
      </p>
      <p>
        16.3
        為免歧義，當懸掛或發佈紅色或黑色暴雨警告，或八號或以上等級的熱帶氣旋警告信號也應視為不可抗力。在此情況下，所有服務及派送應即時取消，並應在前述警告等級降低或除下後安全可行的情況下儘快恢復服務及派送。
      </p>
      <p>16.4 若發生不可抗力，本公司將努力儘快與閣下取得聯繫，並將採取所有合理措施減少對服務的影響。</p>
      <h4>17. 賠償</h4>
      <p>
        17.1
        對於因閣下的任何違約行為產生的所有索賠、責任、損害、成本、費用、損失及法律費用，閣下同意根據要求向本公司、本公司職員、代理、供應商及董事進行賠償、為其進行抗辯並使其不致因此而遭受損害。本條款同樣適用於因閣下使用本網站或服務，或您的帳戶下任何其他主體使用本網站或服務而產生的任何其他責任。
      </p>
      <h4>18. 通知</h4>
      <p>18.1 閣下發送給本公司的任何通知必須以書面形式通過電子郵件發送至info@roomyr.com。</p>
      <p>
        18.2
        通知應在寄件者發出電子郵件後視為已發送，但前提是電子郵件寄件者未收到電子郵件指明擬定收件人沒有收到電子郵件信息。
      </p>
      <h4>19. 免責條款</h4>
      <p>
        19.1
        本公司以當前可用的狀態提供網站，包括其中的所有錯誤。本公司不保證使用網站時將不含任何錯誤或不中斷或任何缺陷將得以糾正。
      </p>
      <p>19.2 本公司將基本根據本協定規定，採用合理謹慎態度和技能提供服務。本公司不就服務作出任何其他承諾或擔保。</p>
      <h4>20. 適用法律及語言</h4>
      <p>20.1 本協定及因本協定或本協定標的或形式構成而產生的或與其相關的任何爭議應受香港法律管轄並據其解釋。</p>
      <p>20.2 若中文版本與英文版本之間存在任何不一致之處，應以英文版本為準。</p>
      <h4>21. 其他約定</h4>
      <p>
        21.1
        本協定及本協定任何書面修正案構成雙方就標的所達成的完整協定，並取代和排除雙方先前達成的任何口頭或書面的協定、諒解或安排。除本協定中指出的本公司隱私政策外，不存在雙方已達成一致但未在本協議中全面規定的聲明、擔保或協定，且除本協定的明確規定外，未授權本公司代表或其代理作出任何聲明、擔保或約定。除非本協定中另有規定，否則所有修正必須以書面形式作出並經雙方簽署。
      </p>
      <p>
        21.2
        本公司保留不時對本協議條款和條件進行修正的權利，且在每次購買本公司服務時，閣下應有責任查看本協議條款和條件。本協議的最新版本始終可在
        www.roomyr.com上獲取。閣下持續使用本公司服務將視為代表閣下持續接受本協議最新版本。若閣下不接受本協議的任何變更，閣下可根據第12條規定終止本協議。
      </p>
      <p>21.3 如有任何爭議，本公司將保留最終決定權。</p>
      <p>21.4 閣下或本公司未能行使或強制執行本協議的任何權利或條款不得構成放棄相關權利或條款。</p>
      <p>
        21.5 本協議所有條款應平等適用于Roomyr Asset Management Limited
        Limited有限公司、其子公司、本公司的任何控股公司、本公司（或前述各個主體）的關聯方,
        加盟商及本公司（和前述各個主體）的第三方資訊提供商及許可方，並應符合其利益，且前述每個主體均應有權直接或代表其自身主張並強制執行相關條款（但是，本協議可在未獲得前述各方同意的情況下進行變更或撤銷）。在遵守前一句規定的前提下，本協議任何條款不可由本協議當事人之外的任何主體強制執行。
      </p>
      <p>
        21.6
        若具有管轄權的任何法院、仲裁機構或行政管理機構確認本協議任何條款無效或不可執行，則相關無效性或不可執行性不得影響本協議其他條款，其他條款應繼續全面有效並具有效力。若本協議任何條款如前所述被認定為無效或不可執行，但在刪除部分內容後可具有效力或可以執行，則應對相關條款進行合理必要的修正，以便使其有效且同時反映雙方意圖。
      </p>
      <p>
        21.7
        在任何情況下，本協定中從其本身性質而言可在終止後合理存續的所有條款應在終止後存續有效，包括但不限於所有權條款、擔保免責條款及責任限制條款。若閣下再次使用網站或服務，則閣下對網站或服務的再次使用將受屆時適用的條款和條件管轄。若閣下使用在本協定項下購買的服務，則適用於相關服務的相關條款將在本協議終止後存續有效。
      </p>
      <p>21.8 各方均應對本協議雙方之間披露的任何及所有資訊進行嚴格保密。本條規定應在本協議終止後仍存續有效。</p>
      <p>
        21.9
        本公司歡迎閣下通過發送電子郵件到info@roomyr.com，向本公司提供意見。在提供任何意見時，閣下同意並確認本公司對相關回饋或建議不承擔保密義務，且相關意見或建議可能會納入本網站或服務之中。
      </p>
      <h4>22. 銷售網絡及聯營公司之利益與關係</h4>
      <p>
        22.1 客戶了解及明白Roomyr提供每間儲存點均為個體獨立公司，該儲存服務均與Roomyr及Roomyr Asset Management Limited
        Limited (除Roomyr Asset Management Limited本身所提供之外)絕無關係，若將來發生任何爭議，均與Roomyr及Roomyr Asset
        Management Limited 無關及不會因此而追究Roomyr及Roomyr Asset Management Limited。
      </p>
    </section>
  </TextPage>
);

export default FaqPage;
